import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import merge from 'lodash/merge';

import { injectReducerAndSaga } from '@shared/redux/injectors/injectReducerAndSaga';

import reducer, { containerKey } from './reducer';
import saga from './saga';
import actions from './actions';
import useAuthSync from './useAuthSync';
import authSelectors from './selectors';
import authConfigurator from './authConfigurator';
import checkAuthQS from './checkAuthQS';

const DEFAULT_OPTIONS = {
  loginUrl: '',
  logoutUrl: '',
  getAuthTokenFromResponse: (response) => response.accessToken,
  getIdTokenFromResponse: (response) => response.idToken,
  withUserInfo: false,
  authPayload: { expires: '', loginType: '' },
  allowQueryStringAuth: false,
};

function AuthFacilitator(
  {
    authTokenExpireTime,
    dispatchFetchAuthDataSuccess,
    dispatchClearAuthData,
    dispatchInvalidateAuthData,
    options: userOptions = DEFAULT_OPTIONS,
  } = {
    userOptions: DEFAULT_OPTIONS,
  },
) {
  const options = merge(DEFAULT_OPTIONS, userOptions);
  useAuthSync({
    authTokenExpireTime,
    dispatchFetchAuthDataSuccess,
    dispatchClearAuthData,
    dispatchInvalidateAuthData,
  });

  useEffect(() => {
    authConfigurator.init(options);
    if (options.allowQueryStringAuth)
      checkAuthQS({ options, dispatchFetchAuthDataSuccess });
  }, []);

  return null;
}

AuthFacilitator.propTypes = {
  authTokenExpireTime: PropTypes.number,
  locationKey: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  dispatchFetchAuthDataSuccess: PropTypes.func.isRequired,
  dispatchClearAuthData: PropTypes.func.isRequired,
  dispatchInvalidateAuthData: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchFetchAuthDataSuccess: actions.fetchAuthDataSuccess,
  dispatchClearAuthData: actions.clearAuthData,
  dispatchInvalidateAuthData: actions.invalidateAuthData,
};

const mapStateToProps = (store) => ({
  locationKey: get(store, 'router.location.key', ''),
  authTokenExpireTime: authSelectors.makeSelectAuthTokenExpireTime(store),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducerAndSaga = injectReducerAndSaga({ key: containerKey, reducer, saga });

export default compose(withReducerAndSaga, withConnect)(memo(AuthFacilitator));
