import produce from 'immer';
import jwtDecode from 'jwt-decode';

import { getAuthDataFromStorage } from './authUtils';
import * as consts from './constants';

export const containerKey = 'Auth';
const { authToken, authTokenExpireTime, user, idToken, withUserInfo } =
  getAuthDataFromStorage();

export const initialState = {
  authToken,
  authTokenExpireTime,
  user,
  idToken,
  loading: false,
  error: false,
  userInfo: withUserInfo ? jwtDecode(authToken) : null,
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case consts.FETCH_AUTH_DATA:
      draft.loading = true;
      draft.error = false;
      break;
    case consts.FETCH_AUTH_DATA_SUCCESS:
      draft.loading = false;
      draft.error = false;
      draft.authToken = action.authToken;
      draft.authTokenExpireTime = action.authTokenExpireTime;
      draft.idToken = action.idToken;
      draft.user = action.user;
      draft.userInfo = action.withUserInfo ? jwtDecode(action.authToken) : null;
      break;
    case consts.FETCH_AUTH_DATA_ERROR:
      draft.loading = false;
      draft.error = true;
      break;
    case consts.CLEAR_AUTH_DATA:
      draft.authToken = null;
      draft.authTokenExpireTime = null;
      draft.idToken = null;
      draft.user = null;
      draft.userInfo = null;
      break;
    case consts.INVALIDATE_AUTH_DATA:
      Object.keys(initialState).forEach((k) => {
        draft[k] = initialState[k];
      });
      break;
  }
}, initialState);

export default reducer;
