import * as consts from './constants';

const fetchAuthData = (credentials) => ({
  type: consts.FETCH_AUTH_DATA,
  credentials,
});

const fetchAuthDataSuccess = ({
  authToken,
  authTokenExpireTime,
  user,
  idToken,
  withUserInfo,
}) => ({
  type: consts.FETCH_AUTH_DATA_SUCCESS,
  authToken,
  authTokenExpireTime,
  user,
  idToken,
  withUserInfo,
});

const fetchAuthDataError = () => ({
  type: consts.FETCH_AUTH_DATA_ERROR,
});

const clearAuthData = () => ({
  type: consts.CLEAR_AUTH_DATA,
});

const invalidateAuthData = () => ({
  type: consts.INVALIDATE_AUTH_DATA,
});

const authActions = {
  fetchAuthData,
  fetchAuthDataSuccess,
  fetchAuthDataError,
  clearAuthData,
  invalidateAuthData,
};

export default authActions;
