const primaryColor = '#538aea';

const beakerTheme = {
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: primaryColor },
    // Used by `getContrastText()` to maximize the contrast
    // between the background and the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    tonalOffset: 0.2,
    greyText: '#979797',
    mainTextColor: '#4E4E4E',
  },
  overrides: {
    MuiInput: {
      root: {
        color: '#4E4E4E',
        fontSize: '1.125rem',
        fontWeight: 400,
      },
      underline: {
        '&:after': {
          borderBottom: `2px solid ${primaryColor}`,
        },
      },
    },
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: primaryColor,
        },
      },
    },
    MuiButton: {
      root: {
        color: primaryColor,
      },
    },
  },
};

export default beakerTheme;
