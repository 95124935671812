import 'custom-event-polyfill';
import Cookies from 'js-cookie';

import history from '@shared/redux/history';

// NOTE:
// As cookies will also be visible to all subdomains We're suffixing the domain to the cookie key name
// See here: https://github.com/js-cookie/js-cookie#domain

const domain = window.location.hostname;
const requestLogout = new CustomEvent('requestLogout');

Cookies.defaults.expires = 10;

export const getAuthDataFromStorage = () => {
  const expireTimeAsString = Cookies.get(`authTokenExpireTime-${domain}`, { domain });
  const expireTime = expireTimeAsString ? parseInt(expireTimeAsString, 10) : null;

  return {
    authToken: Cookies.get(`authToken-${domain}`, { domain }) ?? null,
    authTokenExpireTime: expireTime,
    user: Cookies.get(`authUser-${domain}`, { domain }) ?? null,
    idToken: Cookies.get(`idToken-${domain}`, { domain }) ?? null,
    withUserInfo: Cookies.get(`withUserInfo-${domain}`, { domain }) === 'true' ?? false,
  };
};

export const saveAuthDataToStorage = ({
  authToken,
  authTokenExpireTime,
  user,
  idToken,
  withUserInfo,
}) => {
  Cookies.set(`authToken-${domain}`, authToken, { domain });
  Cookies.set(`authTokenExpireTime-${domain}`, authTokenExpireTime, { domain });
  Cookies.set(`authUser-${domain}`, user, { domain });
  Cookies.set(`idToken-${domain}`, idToken, { domain });
  Cookies.set(`withUserInfo-${domain}`, withUserInfo, { domain });
};

export const clearAuthDataFromStorage = () => {
  Cookies.remove(`authToken-${domain}`, { domain });
  Cookies.remove(`authTokenExpireTime-${domain}`, { domain });
  Cookies.remove(`authUser-${domain}`, { domain });
  Cookies.remove(`idToken-${domain}`, { domain });
  Cookies.remove(`withUserInfo-${domain}`, { domain });
};

// Share login event with other tabs
export const triggerCrossTabLogin = (authData) => {
  localStorage.setItem('loginEvent', JSON.stringify(authData));
  localStorage.removeItem('loginEvent');
};

export const triggerLogoutEvent = () => {
  // Logout from current tab
  window.dispatchEvent(requestLogout);
  // Share logout event with other tabs
  localStorage.setItem('logoutEvent', '');
  localStorage.removeItem('logoutEvent');
  // Redirect user back to initial route under the assumption
  // each App consuming will handle routing logic of it's own
  history.push('/');
};
