import jwtDecode from 'jwt-decode';
import { takeLatest, put, call } from 'redux-saga/effects';
import get from 'lodash/get';

import request from '@shared/utils/networkHelpers';

import { saveAuthDataToStorage, triggerCrossTabLogin } from './authUtils';
import authConfigurator from './authConfigurator';
import * as consts from './constants';
import actions from './actions';

export function* fetchAuth({ credentials }) {
  try {
    const {
      loginUrl,
      getAuthTokenFromResponse,
      getIdTokenFromResponse,
      withUserInfo,
      authPayload,
    } = authConfigurator.getConfigOptions();

    const { expires, loginType } = authPayload;
    const response = yield call(request, {
      isLoginRequest: true,
      url: loginUrl,
      options: { method: 'POST', body: JSON.stringify(credentials) },
    });
    const authToken = yield call(getAuthTokenFromResponse, response);
    const idToken = yield call(getIdTokenFromResponse, response);

    const decoded = yield call(jwtDecode, authToken);

    const authTokenExpireTime = get(decoded, expires, 0) * 1e3;
    const user = get(credentials, loginType, '');

    // Store data in localStorage
    yield call(saveAuthDataToStorage, {
      authToken,
      authTokenExpireTime,
      user,
      idToken,
      withUserInfo,
    });

    // Trigger login in other tabs of the same app (if they exist)
    yield call(triggerCrossTabLogin, {
      authToken,
      authTokenExpireTime,
      user,
      idToken,
      withUserInfo,
    });

    // Put the relevant data in the redux store
    yield put(
      actions.fetchAuthDataSuccess({
        authToken,
        authTokenExpireTime,
        user,
        idToken,
        withUserInfo,
      }),
    );
  } catch (err) {
    yield put(actions.fetchAuthDataError());
  }
}

export default function* defaultSaga() {
  yield takeLatest(consts.FETCH_AUTH_DATA, fetchAuth);
}
