import { useEffect } from 'react';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import isString from 'lodash/isString';
import Visibility from 'visibilityjs';

import { clearAuthDataFromStorage, getAuthDataFromStorage } from './authUtils';

let expiryTimeout = null;

// Helper
function preParse(date) {
  return isString(date) ? parseISO(date) : date;
}

function useAuthSync({
  authTokenExpireTime,
  dispatchFetchAuthDataSuccess,
  dispatchClearAuthData,
  dispatchInvalidateAuthData,
}) {
  // Note: All logouts go through here
  // eslint-disable-next-line no-unused-vars
  function handleLocalLogout({ sessionTimeout = false }) {
    clearAuthDataFromStorage(); // localStorage Redux
    dispatchClearAuthData(); // From Redux
  }

  // This is only triggered in inactive tabs where the app is open
  function crossTabAuthEvent(e) {
    // Checking for e.newValue to filter out the second event (triggered by localStorage.removeItem)
    if (e.newValue === null) return;
    switch (e.key) {
      case 'loginEvent': {
        const authData = JSON.parse(e.newValue);
        dispatchFetchAuthDataSuccess(
          authData.authToken,
          authData.authTokenExpireTime,
          authData.email,
          authData.idToken,
        );
        break;
      }
      case 'logoutEvent': {
        handleLocalLogout({});
        break;
      }
      default: {
        break;
      }
    }
  }

  // Used instead of handleLocalLogout for when token invalidation is needed
  function handleLogoutAndInvalidateAuthData({ sessionTimeout = false }) {
    // Invalidate auth data only from the current tab
    dispatchInvalidateAuthData();
    // Logout from current tab
    handleLocalLogout({ sessionTimeout });
  }

  // If signed in, calculate the time until expiry and
  // set a timeout to trigger a sign out (for all tabs) when that time comes
  function manageExpiryTimeout(expireTime) {
    clearTimeout(expiryTimeout);
    const now = new Date();

    // If null/not set, nothing to do
    if (expireTime === null) return;

    // Next, check that the auth token isn't already expired
    if (isAfter(preParse(expireTime), now)) {
      // If still valid, set the timeout
      const timeLeft = differenceInMilliseconds(expireTime, now);
      expiryTimeout = setTimeout(
        () => handleLogoutAndInvalidateAuthData({ sessionTimeout: true }),
        timeLeft,
      );
    } else if (isBefore(preParse(expireTime), now)) {
      // If already invalid, sign out
      handleLocalLogout({ sessionTimeout: true });
    }
  }

  function handleVisibilityChange(e, state) {
    const { authToken } = getAuthDataFromStorage();
    if (state === 'visible') {
      manageExpiryTimeout(authTokenExpireTime);
      if (!authToken) {
        handleLocalLogout({});
      }
    }
  }

  useEffect(() => {
    // Listen to logout requests from current tab
    window.addEventListener('requestLogout', handleLogoutAndInvalidateAuthData);
    manageExpiryTimeout(authTokenExpireTime);

    // Manage expiration on tab visibility change
    const visibilityListener = Visibility.change(handleVisibilityChange);

    return () => {
      window.removeEventListener('requestLogout', handleLogoutAndInvalidateAuthData);
      window.removeEventListener('storage', crossTabAuthEvent);
      Visibility.unbind(visibilityListener);
    };
  }, [
    authTokenExpireTime,
    dispatchFetchAuthDataSuccess,
    dispatchClearAuthData,
    dispatchInvalidateAuthData,
  ]);
}

export default useAuthSync;
