import { lazy, Suspense } from 'react';

import DefaultLoader from '../components/Loader';

const loadable = (
  importFunc,
  { Fallback = DefaultLoader } = { Fallback: DefaultLoader },
) => {
  const LazyComponent = lazy(importFunc);

  return (props) => (
    <Suspense fallback={<Fallback />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;
