import ReactDOM from '@hot-loader/react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/core/styles';

import generateMUITheme from '@shared/utils/generateMUITheme';
import { AuthFacilitator } from '@shared/auth';
import history from '@shared/redux/history';
import configureStore from '@shared/redux/configureStore';

import themeOverrides from 'utils/theme';

import App from 'containers/App';

import './images/favicon.ico';

import '@shared/styles/global-styles.scss';
import '@shared/fonts/roboto/font-faces.scss';
// Local global styles to override some css
import './styles.scss';

const store = configureStore();
const theme = generateMUITheme(themeOverrides);

const authOptions = {
  loginUrl: process.env.LOGIN_URL,
  logoutUrl: 'LOGOUT_URL',
  getAuthTokenFromResponse: (response) => response.accessToken,
  getIdTokenFromResponse: (response) => response.accessToken,
  withUserInfo: true,
  authPayload: { expires: 'expiresIn', loginType: 'username' },
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
        <AuthFacilitator options={authOptions} />
      </ConnectedRouter>
    </Provider>
  </ThemeProvider>,
  document.getElementById('app'),
);
