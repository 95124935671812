import invariant from 'invariant';
import conformsTo from 'lodash/conformsTo';
import isString from 'lodash/isString';
import isFunction from 'lodash/isFunction';
import isBoolean from 'lodash/isBoolean';
import isObject from 'lodash/isObject';

let configOptions = {};

const checkAuthOptions = (options) => {
  const shape = {
    loginUrl: isString,
    logoutUrl: isString,
    getAuthTokenFromResponse: isFunction,
    getIdTokenFromResponse: isFunction,
    withUserInfo: isBoolean,
    authPayload: isObject,
    allowQueryStringAuth: isBoolean,
  };
  invariant(
    conformsTo(options, shape),
    '<AuthFacilitator>: Expected a valid options object',
  );
};

const init = (options) => {
  checkAuthOptions(options);
  configOptions = options;
};

const getConfigOptions = () => configOptions;

const authConfiguration = {
  init,
  getConfigOptions,
};

export default authConfiguration;
