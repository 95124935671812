import PropTypes from 'prop-types';

import styles from './style.scss';

function Loader({ color }) {
  return <div style={color ? { color } : null} className={styles.loader} />;
}

Loader.propTypes = {
  color: PropTypes.string,
};

export default Loader;
