import { createSelector } from 'reselect';
import get from 'lodash/get';
import isBefore from 'date-fns/isBefore';

import { initialState, containerKey } from './reducer';

const selectAuthDomain = (state) => get(state, [containerKey], initialState);

const makeSelectAuth = createSelector(selectAuthDomain, (substate) => substate);

const makeSelectAuthToken = createSelector(selectAuthDomain, (substate) =>
  get(substate, 'authToken'),
);

const makeSelectAuthTokenExpireTime = createSelector(selectAuthDomain, (substate) =>
  get(substate, 'authTokenExpireTime'),
);

const makeSelectUserInfo = createSelector(selectAuthDomain, (substate) =>
  get(substate, 'userInfo'),
);

const makeSelectIsUserAuthenticated = createSelector(
  makeSelectAuthToken,
  makeSelectAuthTokenExpireTime,
  (token, expireTime) => !!token && !isBefore(expireTime, new Date().getTime()),
);

const authSelectors = {
  makeSelectAuth,
  selectAuthDomain,
  makeSelectAuthToken,
  makeSelectAuthTokenExpireTime,
  makeSelectIsUserAuthenticated,
  makeSelectUserInfo,
};

export default authSelectors;
