import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { ReactReduxContext } from 'react-redux';

import { getSagaInjectors } from './sagaInjectors';
import { getReducerInjectors } from './reducerInjectors';

/**
 * A higher-order component that dynamically injects a reducer
 * and/or a saga when the component is instantiated
 *
 * @param {Object} params
 * @param {string} params.key The key to inject the reducer under
 * @param {function} params.reducer The reducer that will be injected
 * @param {function} params.saga The saga that will be injected
 * @param {string} [params.mode] The saga injection behaviour to use. The default is
 * `SagaInjectionModes.DAEMON` which causes the saga to be started on component
 * instantiation and never canceled or started again.
 *
 * @example
 *
 * functin MyContainer() {
 *   return null;
 * }
 *
 * export default injectReducerAndSaga({ key: "myContainerKey", reducer, saga })(MyContainer)
 *
 * @public
 */

const injectReducerAndSaga =
  ({ key, reducer, saga, mode }) =>
  (WrappedComponent) => {
    class ReducerAndSagaInjector extends React.Component {
      static WrappedComponent = WrappedComponent;

      static contextType = ReactReduxContext;

      /* eslint-disable local/prefer-nullish-coalescing-operator */
      static displayName = `withReducerAndSaga(${
        WrappedComponent.displayName || WrappedComponent.name || 'Component'
      })`;
      /* eslint-enable local/prefer-nullish-coalescing-operator */

      constructor(props, context) {
        super(props, context);

        // Inject reducer
        if (reducer) getReducerInjectors(context.store).injectReducer(key, reducer);

        // Get saga injectors and inject saga
        if (saga) {
          this.sagaInjectors = getSagaInjectors(context.store);
          this.sagaInjectors.injectSaga(key, { saga, mode });
        }
      }

      componentWillUnmount() {
        if (saga) this.sagaInjectors.ejectSaga(key);
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return hoistNonReactStatics(ReducerAndSagaInjector, WrappedComponent);
  };

export { injectReducerAndSaga };
