import jwtDecode from 'jwt-decode';
import omit from 'lodash/omit';
import get from 'lodash/get';
import queryString from 'query-string';

import history from '@shared/redux/history';

import {
  getAuthDataFromStorage,
  saveAuthDataToStorage,
  triggerLogoutEvent,
  clearAuthDataFromStorage,
} from './authUtils';

const QUERY_STRING_AUTH_KEY = 'auth';

export default function checkAuthQS({
  options: {
    withUserInfo,
    authPayload: { expires },
  },
  dispatchFetchAuthDataSuccess,
}) {
  const {
    replace,
    location: { search },
  } = history;

  // Check URL query strings for the key passed in options
  const parsedQs = queryString.parse(search);
  const qsAuthToken = parsedQs[QUERY_STRING_AUTH_KEY];

  // Get authToken from localStorage
  const { authToken: authFromStorage } = getAuthDataFromStorage();
  // If token exist in localStorage and we get auth query string
  // If they're both the same, bail out (we don't to do anything else)
  // If they're different, We clear the existing token from LS and continue with the received token from the query param
  if (authFromStorage && !!qsAuthToken) {
    if (authFromStorage === qsAuthToken) return;
    if (authFromStorage !== qsAuthToken) clearAuthDataFromStorage();
  }

  if (qsAuthToken) {
    let decoded;
    try {
      decoded = jwtDecode(qsAuthToken);
      const authTokenExpireTime = get(decoded, expires, 0) * 1e3;
      const authData = {
        authToken: qsAuthToken,
        authTokenExpireTime,
        user: null,
        idToken: qsAuthToken,
        withUserInfo,
      };
      saveAuthDataToStorage(authData);
      dispatchFetchAuthDataSuccess(authData);

      // REMOVE auth query param from URL & maintain other query params if exist
      const omittedQs = omit(parsedQs, [QUERY_STRING_AUTH_KEY]);
      const stringified = queryString.stringify(omittedQs);
      replace({ search: stringified });
    } catch (e) {
      // Handle Foul / invalid tokens
      triggerLogoutEvent();
    }
  }
}
