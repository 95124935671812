// import fetch from './mocks/fetch';
import { getAuthDataFromStorage } from '../auth/authUtils';

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(`[${response.status}]: ${response.statusText}`);
  error.response = response;
  throw error;
}

export default function request({ isLoginRequest = false, url, options = {} }) {
  const headers = new Headers();
  if (!isLoginRequest) {
    const { authToken } = getAuthDataFromStorage();

    headers.append('Authorization', authToken);
  }
  headers.append('Content-Type', 'application/json');
  const newRequest = new Request(url, {
    headers,
    ...options,
  });
  return fetch(newRequest).then(checkStatus).then(parseJSON);
}
