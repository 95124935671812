import { createMuiTheme } from '@material-ui/core/styles';
import merge from 'lodash/merge';

const healthyDefaults = {
  typography: {
    useNextVariants: true,
  },
  palette: {
    // Used by `getContrastText()` to maximize the contrast
    // between the background and the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    tonalOffset: 0.2,
  },
};

export default function generateMUITheme(overrides = {}) {
  const combinedTheme = merge(healthyDefaults, overrides);
  const theme = createMuiTheme(combinedTheme);

  // Make theme globally available for console exploration
  if (process.env.NODE_ENV === 'development') window.muiTheme = theme;

  return theme;
}
