import { hot } from 'react-hot-loader/root';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { authSelectors } from '@shared/auth';

import AuthenticatedApp from './authenticated-app-loadable';
import UnauthenticatedApp from './unauthenticated-app-loadable';

const selector = createStructuredSelector({
  isUserAuthenticated: authSelectors.makeSelectIsUserAuthenticated,
  auth: authSelectors.makeSelectAuth,
});

function App() {
  const {
    isUserAuthenticated,
    auth: { user },
  } = useSelector(selector);

  const location = useSelector((state) => state.router.location);
  return isUserAuthenticated ? (
    <AuthenticatedApp location={location} user={user} />
  ) : (
    <UnauthenticatedApp location={location} />
  );
}

export default hot(App);
